import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { ChangeStatus, userMgmtData } from "../Redux/Actions/adminaction";
import Pagination from "../Components/Layout/Pagination";
import * as XLSX from "xlsx";
import logo from "../Assets/Images/C.svg";
import { employepgno, emppath } from "../Redux/Reducer/userMgmtSlice";

export default function Employers() {
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const dispatch = useDispatch();
  const location = useLocation()
  const data = useSelector((state) => state.usermgmtSlice.userMgmtData);
  const pagenofromredux = useSelector(
    (state) => state.usermgmtSlice.employepgno
  );
  console.log(data);
  const [pages, setPages] = useState(pagenofromredux ? pagenofromredux : "1");
  const [limit, setlimit] = useState("10");
  const [search, setSearch] = useState("");
  const [flag, setflag] = useState(false);

  useEffect(() => {
    dispatch(
      userMgmtData({ page: pages, search: search, limit: limit, role: "1" })
    );
  }, [search, pages, limit, flag]);

  const fetchDataAndExportToExcel = async () => {
    try {
      const response = await dispatch(
        userMgmtData({ page: "", search: "", limit: "", role: "1" })
      );
      console.log(response);

      const allData = response?.payload?.data?.users;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  };

  useEffect(() => {
    dispatch(employepgno(pages));
  }, [pages]);

  useEffect(() => {
    dispatch(emppath(location?.pathname?.split("/")?.[1]));
  }, [location?.pathname]);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Employers</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    name="search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search.."
                  />
                </Form.Group>
              </Col>

              <Col xxl={2} xl={3} lg={4} md={4}>
                <Form.Select
                  onChange={(e) =>
                    dispatch(
                      userMgmtData({
                        page: pages,
                        search: search,
                        limit: limit,
                        role: 1,
                        type: e.target.value,
                      })
                    )
                  }
                  aria-label="Default select example"
                >
                  <option value="">All</option>
                  <option value="1">Active</option>
                  <option value="0">Suspend </option>
                </Form.Select>
              </Col>
              <Col
                xxl={7}
                xl={6}
                lg={4}
                md={4}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link onClick={fetchDataAndExportToExcel}>DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/UserManagement"> Candidates</Link>
              </li>
              <li>
                <Link to="/Employers" className="active-tab">
                  Employers
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {1 + (pages - 1) * limit} -{" "}
                {data?.users?.length + (pages - 1) * limit} of{" "}
                {data?.totalCount} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={(e) => setlimit(e.target.value)}
                  name="limit"
                  value={limit}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Joined on</th>
                <th>Company name</th>
                <th>Representative Name</th>
                <th>Company Location</th>
                <th>Image</th>
                <th>Email</th>
                <th>status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.users?.map((x, index) => {
                const serialnumber = (pages - 1) * limit + index + 1;
                return (
                  <tr>
                    <td>{serialnumber}</td>
                    <td>
                      {new Date(x.createdAt).toLocaleDateString() || "N/A"}
                    </td>
                    <td>
                      <Link to={`/UserProfileEmployer/${x?._id}`}>
                        {x?.company_name || "N/A"}
                      </Link>
                    </td>
                    <td>{x?.full_name || "N/A"}</td>
                    <td>{x?.company_location || "N/A"}</td>
                    <td className="user-img">
                      {x?.profile_image ? (
                        <img src={`${BASE_URL}${x?.profile_image}`} />
                      ) : (
                        <img src={logo} />
                      )}
                    </td>

                    <td>{x?.email}</td>
                    <td>{x?.status == 1 ? "Active" : "In-active"}</td>
                    <td>
                      <div className="select-box">
                        <Form.Select
                          value={x?.status}
                          onChange={(e) => {
                            dispatch(
                              ChangeStatus({
                                userId: x?._id,
                                type: e.target.value,
                              })
                            ).then((res) => {
                              if (res?.payload?.success) {
                                setflag(!flag);
                              }
                            });
                          }}
                          aria-label="Default select example"
                        >
                          <option value="0">Suspend</option>
                          <option value="1">Active</option>
                        </Form.Select>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {data?.users?.length == 0 && (
            <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
            </div>
          )}
        </div>
      </Container>

      <Pagination
        totalstuff={data?.totalCount}
        limit={limit}
        setPages={setPages}
        search={search}
      />
    </Layout>
  );
}
