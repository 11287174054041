import { createAsyncThunk } from "@reduxjs/toolkit";
import  adminapi  from "../../services/adminapi";
import { toast } from "react-toastify";



export const adminLogin = createAsyncThunk("adminLogin",async(details)=>{
    const {data} = await adminapi.post("/auth/login",details)
    
    console.log(data)
    if(data?.success){
        toast.success(data?.message)
        sessionStorage.setItem("token",data?.data?.token)
        window.location.href="/Dashboard"
    }else if(!data?.success){
        toast.error(data?.message)
    }

    return data

})