import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { reportList } from "../Redux/Actions/adminaction";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../Components/Layout/Pagination";
import * as XLSX from "xlsx";

export default function Report() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.rprtSlice.listing);
  console.log(data, "data");
  const [pages, setPages] = useState("1");
  const [limit, setlimit] = useState("10");
  const [search, setSearch] = useState("");
  const [flag, setflag] = useState(false);

  useEffect(() => {
    dispatch(reportList({ page: pages, search: search, limit: limit }));
  }, [search, pages, limit, flag]);

  const fetchDataAndExportToExcel = async () => {
    try {
      const response = await dispatch(
        reportList({ page: "", search: "", limit: "" })
      );
      console.log(response);

      const allData = response?.payload?.users;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  };
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Report</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    onChange={(e) => setSearch(e.target.value)}
                    type="search"
                    placeholder="Search.."
                  />
                </Form.Group>
              </Col>
              <Col
                xxl={9}
                xl={9}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link onClick={fetchDataAndExportToExcel} to="#">
                    Download
                  </Link>{" "}
                </div>
                {/* <div className="cmn-btn">
                  <Link to="/ReportDetails">Report</Link>{" "}
                </div> */}
              </Col>
            </Row>
          </div>
        </Container>
        {/* <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/Administrators" className="active-tab">
                  ALL
                </Link>
              </li>
              <li>
                <Link to="#">Super Admin 2</Link>
              </li>
              <li>
                <Link to="#">Accounts 1</Link>
              </li>
            </ul>
          </div>
        </Container> */}
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {1 + (pages - 1) * limit} -{" "}
                {data?.users?.length + (pages - 1) * limit} of{" "}
                {data?.totalCount} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={(e) => setlimit(e.target.value)}
                  name="limit"
                  value={limit}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Date</th>
                <th>Reported by</th>
                <th>Reported to</th>
                <th>Reason</th>
                <th>Description</th>
                {/* <th>Status</th>  */}
              </tr>
            </thead>
            <tbody>
              {data?.users?.map((user, index) => {
                return (
                  <tr>
                    <td>{(pages - 1) * limit + index + 1}</td>
                    <td>
                      {new Date(user?.createdAt).toLocaleDateString()}
                      <br />
                      <span className="time">12:03</span>
                    </td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/ReportDetails/${user?._id}`)}
                    >
                      {user?.block_report_by?.full_name || "N/A"}
                    </td>
                    <td>{user?.block_report_to?.full_name || "N/A"}</td>
                    <td>
                      {user?.title == "0"
                        ? "fake Profile"
                        : user?.title == "1"
                        ? "Inappropriate Photos"
                        : user?.title == "2"
                        ? "Inappropriate messages"
                        : user?.title == "3"
                        ? "Scam"
                        : user?.title == "4"
                        ? "Bad Offline Behaviour"
                        : user?.title == "5"
                        ? "UnderAge"
                        : "I'm just not interested"}
                    </td>
                    <td>{user?.description || "N/A"}</td>
                  </tr>
                );
              })}
              
            </tbody>
          </Table>

          {data?.users?.length == 0 && (
            <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
            </div>
          )}
        </div>
      </Container>

      <Pagination
        totalstuff={data?.totalCount}
        limit={limit}
        setPages={setPages}
        search={search}
      />
    </Layout>
  );
}
