import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";

ChartJS.register(ArcElement, Tooltip, Legend);


export default function Users() {

  const data1 = useSelector((state)=>state.dashBoardSlice.dashboardData)
  console.log(data1)

  const data = {
    labels: ["Candidate", "Employer"],
    datasets: [
      {
        label: "# of Votes",
        data: [data1?.totalCandidates, data1?.totalEmployeers],
        backgroundColor: ["#8958FE", "#108FFA"],
  
        borderWidth: 1,
      },
    ],
  };
  return <Doughnut className="gender-chart" data={data} />;
}
