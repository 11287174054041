import { toast } from "react-toastify";
import adminapi from "../../services/adminapi";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const adminLogout = createAsyncThunk("adminLogout", async () => {
  const { data } = await adminapi.post("/auth/logout");

  if (data?.success) {
    toast.success(data?.message);
    window.location.href = "/";
  } else if (!data?.success) {
    toast.error(data?.message);
  }
});

// FORGET PASSWORD

export const forgetAdminPassword = createAsyncThunk(
  "forgetAdminPassword",
  async (details) => {
    const { data } = await adminapi.post("/auth/forgetPassword", details);

    return data;
  }
);

// OTP

export const otpverification = createAsyncThunk(
  "otpverification",
  async (details) => {
    const { data } = await adminapi.post("/auth/verifyotp", details);
    return data;
  }
);

// RESET PASSWORD

export const resetAdminPassword = createAsyncThunk(
  "resetAdminPassword",
  async (details) => {
    const { data } = await adminapi.post("/auth/resetPassword", details);
    if (data?.success) {
      toast.success(data?.message);
      window.location.href = "/Dashboard";
    } else if (!data?.success) {
      toast.error(data?.message);
    }
    return data;
  }
);

// CHANGE PASSWORD

export const ChangeAdminPassword = createAsyncThunk(
  "ChangePassword",
  async (details) => {
    const { data } = await adminapi.post("/auth/changePassword", details);

    if (data?.success) {
      toast.success(data?.message);
      window.location.href = "/Dashboard";
    } else if (!data?.success) {
      toast.error(data?.message);
    }

    return data;
  }
);

//  USER MANAGEMENT LISTING

export const userMgmtData = createAsyncThunk(
  "userMgmtData",
  async (details) => {
    const { data } = await adminapi.get(
      `/userManagement?page=${details?.page}&limit=${details?.limit}&name=${details.search}&Role=${details.role}&type=${details.type}`
    );
    return data;
  }
);

// GET USER DETIALS

export const getuserDetails = createAsyncThunk(
  "getuserDetails",
  async (details) => {
    const { data } = await adminapi.get(`/userProfile?userId=${details}`);
    return data;
  }
);
export const getDashboard = createAsyncThunk(
  "getDashboard",
  async (details) => {
    const { data } = await adminapi.get(`/dashBoard `);
    return data;
  }
);

// CHANGE STATUS

export const ChangeStatus = createAsyncThunk(
  "ChangeStatus",
  async (details) => {
    const { data } = await adminapi.post("/activateDeactivateUser", details);

    if (data?.success) {
      toast.success(data?.message);
    } else if (!data?.success) {
      toast.error(data?.message);
    }

    return data;
  }
);

export const saveprofiledata = createAsyncThunk(
  "saveprofiledata",
  async (details) => {
    console.log(details, "details");
    const { data } = await adminapi.put("/editUserProfile", details);

    if (data?.success) {
      toast.success(data?.message);
    } else if (!data?.success) {
      toast.error(data?.message);
    }

    return data;
  }
);

// DELETE ACCOUNT

export const deleteAccount = createAsyncThunk(
  "deleteAccount",
  async (details) => {
    const { data } = await adminapi.post("/deleteAccount", details);

    if (data?.success) {
      toast.success(data?.message);
    } else if (!data?.success) {
      toast.error(data?.message);
    }

    return data;
  }
);

// ADD NOTIFICATION

export const createNotification = createAsyncThunk(
  "createNotification",
  async (details) => {
    const { data } = await adminapi.post("/addNotification", details);

    if (data?.success) {
      toast.success(data?.message);
    } else if (!data?.success) {
      toast.error(data?.message);
    }

    return data;
  }
);

// list notification

export const notificationList = createAsyncThunk(
  "notificationList",
  async (details) => {
    const { data } = await adminapi.get(
      `/getAllNotifications?page=${details?.page}&limit=${details?.limit}&title=${details.search}`
    );
    return data;
  }
);

// Delete Notificastion

export const deleteNoti = createAsyncThunk("deleteNoti", async (details) => {
  const { data } = await adminapi.post("/deleteNotification ", details);

  if (data?.success) {
    toast.success(data?.message);
  } else if (!data?.success) {
    toast.error(data?.message);
  }

  return data;
});

// list reports

export const reportList = createAsyncThunk("reportList", async (details) => {
  const { data } = await adminapi.get(
    `/getReports?page=${details?.page}&limit=${details?.limit}&name=${details.search}`
  );
  return data;
});

// report details

export const getreportDetails = createAsyncThunk(
  "getreportDetails",
  async (details) => {
    const { data } = await adminapi.get(`/reportDetails?reportId=${details}`);
    return data;
  }
);

// Get support chat

export const getSupportChat = createAsyncThunk("getSupportChat", async (id) => {
  const { data } = await adminapi.get(`/getSupportById?id=${id}`);
  return data;
});

// get Allsupport

export const getAllSupport = createAsyncThunk(
  "getAllSupport",
  async (details) => {
    const { data } = await adminapi.get(
      `/getSupports?page=${details?.page}&limit=${details.limit}&search=${details.search}&status=${details.status}`
    );
    return data;
  }
);

// change support status

export const changeSupportStatus = createAsyncThunk(
  "changeSupportStatus",
  async (details) => {
    const { data } = await adminapi.post("/changeSupportStatus", details);
    if (data?.success) {
      toast.success(data?.message);
    } else {
      toast.error(data?.message);
    }

    return data;
  }
);

// list jobs

export const jobsList = createAsyncThunk("jobsList", async (details) => {
  const { data } = await adminapi.get(
    `/getJobs?page=${details?.page}&limit=${details?.limit}&name=${details.search}&date=${details.date}`
  );
  return data;
});

//  job details

export const jobdetail = createAsyncThunk("jobdetail", async (id) => {
  const { data } = await adminapi.get(`/jobDetails?job_id=${id}`);
  return data;
});

// Delete job

export const deletejob = createAsyncThunk("deletejob", async (details) => {
  const { data } = await adminapi.post("/deletejob ", details);

  if (data?.success) {
    toast.success(data?.message);
  } else if (!data?.success) {
    toast.error(data?.message);
  }

  return data;
});

// createAdd

export const createAdd = createAsyncThunk("createAdd", async (details) => {
  const { data } = await adminapi.post("/createAds ", details);

  if (data?.success) {
    toast.success(data?.message);
  } else if (!data?.success) {
    toast.error(data?.message);
  }

  return data;
});

// list add

export const addsList = createAsyncThunk("addsList", async (details) => {
  const { data } = await adminapi.get(
    `/getAllAds?page=${details?.page}&limit=${details?.limit}&title=${details.search}&date=${details.date}`
  );
  return data;
});

// delete add


export const deleteadd = createAsyncThunk("deleteadd", async (details) => {
  const { data } = await adminapi.post("/DeleteAds  ", details);

  if (data?.success) {
    toast.success(data?.message);
  } else if (!data?.success) {
    toast.error(data?.message);
  }

  return data;
});