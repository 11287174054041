import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";

ChartJS.register(ArcElement, Tooltip, Legend);



export default function Orders() {

  const data1 = useSelector((state)=>state.dashBoardSlice.dashboardData)
  console.log(data1)

  const data = {

    labels: ["Active ", "Inactive"],
    datasets: [
      {
        label: "# of Votes",
        data: [data1?.activeUsers, data1?.deActivatedUsers],
        backgroundColor: ["#8958FE", "#108FFA"],
  
        borderWidth: 1,
      },
    ],
  };

  return <Doughnut className="gender-chart" data={data} />;
}
