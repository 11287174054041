import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import { jobsList } from "../Redux/Actions/adminaction";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../Components/Layout/Pagination";

export default function UserVerification() {

  const data = useSelector((state) => state?.jobdata?.listing);
  console.log(data, "data");
  const navigate=useNavigate()
  const dispatch = useDispatch();
  const [pages, setPages] = useState("1");
  const [date, setDate] = useState("");
  const [limit, setlimit] = useState("10");
  const [search, setSearch] = useState("");
  const [flag, setflag] = useState(false);
  const [showdel, setshowdel] = useState(false);
  const [id,setId] = useState("")

  useEffect(() => {
    dispatch(jobsList({ page: pages, search: search, limit: limit,date:date }));
  }, [search, pages, limit, flag,date]);
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Manage Jobs</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control onChange={(e)=>setSearch(e.target.value)} type="search" placeholder="Search.." />
                </Form.Group>
              </Col>

              <Col xxl={2} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control onChange={(e)=>setDate(e.target.value)} type="date" placeholder="Search.." />
                </Form.Group>
              </Col>
              <Col
                xxl={6}
                xl={6}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                {/* <div className="cmn-btn">
                  <Link to="/JobDetails">Job Details</Link>
                </div> */}
              </Col>
            </Row>
          </div>
        </Container>

        {/* <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/UserVerification" className="active-tab">
                  ALL
                </Link>
              </li>
            </ul>
          </div>
        </Container> */}
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">Showing {1 + (pages - 1) * limit} -{" "}
                {data?.notifications?.length + (pages - 1) * limit} of{" "}
                {data?.totalCount} results</div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={(e) => setlimit(e.target.value)}
                  name="limit"
                  value={limit}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="p-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Create On date</th>
                <th>Designation</th>
                <th>Location</th>
                <th>Offered package</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {data?.notifications?.map((job,index)=>{
              return (
                <tr>
                <td>{(pages - 1) * limit + index + 1}</td>
                <td>{new Date(job?.createdAt).toLocaleDateString()}</td>
                <td style={{cursor:"pointer"}} onClick={()=>navigate(`/JobDetails/${job?._id}`)}>{job?.desigination || "N/a"}</td>
                <td>{job?.location?.location_name || "N/A"}</td>
                <td>
                 {job?.offer_package || "N/A"}
                </td>
                <td className="cmn-btn">
                  <Link className="account-btn common-colr-btn">
                    Delete
                  </Link>
                </td>
              </tr>

              )
            })}
             
             
            </tbody>
          </Table>
          {data?.notifications?.length == 0 && (
            <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
            </div>
          )}
        </div>
      </Container>

      <Pagination
        totalstuff={data?.totalCount}
        limit={limit}
        setPages={setPages}
        search={search}
      />
    </Layout>
  );
}
