import { configureStore } from "@reduxjs/toolkit";
import { userMgmtSlice } from "./Reducer/userMgmtSlice";
import { dashboardSlice } from "./Reducer/dashBoardSlice";
import {notificationSlice} from "./Reducer/notificationSlice";
import {reportSlice} from "./Reducer/reportSlice";
import { supportSlice } from "./Reducer/SupportSlice";
import {jobSlice} from "./Reducer/jobSlice";
import {adsSlice} from "./Reducer/adsSlice";

export const store = configureStore({
  reducer: {
    usermgmtSlice: userMgmtSlice.reducer,
    dashBoardSlice: dashboardSlice.reducer,
    notiSlice:notificationSlice.reducer,
    rprtSlice:reportSlice.reducer,
    supportchat: supportSlice.reducer,
    jobdata:jobSlice.reducer,
    addData:adsSlice.reducer
  },
});
