import { createSlice } from "@reduxjs/toolkit";
import { getDashboard } from "../Actions/adminaction";
import { toast } from "react-toastify";

export const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState: {
    dashboardData:null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDashboard.fulfilled, (state, action) => {
      if (action?.payload?.success) {
        state.dashboardData = action.payload.data;
      } else {
        toast.error(action?.payload?.message);
      }
    });
  },
});

export default dashboardSlice.reducer;
