import axios from "axios";

const adminapi = axios.create({

  baseURL: process.env.REACT_APP_ADMIN_BASE_URL,
  headers: {
    Authorization: sessionStorage.getItem("token"),
  },
});

adminapi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return error.response
  }
);



export default adminapi
