import { createSlice } from "@reduxjs/toolkit";
import { notificationList } from "../Actions/adminaction";
import { toast } from "react-toastify";

export const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState: {
    listing:""
   
  },
  reducers: {
   
  },
  extraReducers: (builder) => {
    builder.addCase(notificationList.fulfilled, (state, action) => {
      if (action?.payload?.success) {
        state.listing = action.payload.data;
      } else {
        toast.error(action?.payload?.message);
      }
    });
   
  },
});


export default notificationSlice.reducer;
