import { createSlice } from "@reduxjs/toolkit";
import { getreportDetails, notificationList, reportList } from "../Actions/adminaction";
import { toast } from "react-toastify";

export const reportSlice = createSlice({
  name: "reportSlice",
  initialState: {
    listing:"",
    details:""
   
  },
  reducers: {
   
  },
  extraReducers: (builder) => {
    builder.addCase(reportList.fulfilled, (state, action) => {
        console.log(action.payload)
      if (action?.payload) {
        state.listing = action.payload;
      } else {
        toast.error(action?.payload?.message);
      }
    });
    builder.addCase(getreportDetails.fulfilled, (state, action) => {
        console.log(action.payload)
      if (action?.payload) {
        state.details = action.payload;
      } else {
        toast.error(action?.payload?.message);
      }
    });
   
  },
});


export default reportSlice.reducer;