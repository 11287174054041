import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Pagination from "../Components/Layout/Pagination";
import { Button } from "react-bootstrap";
import { changeSupportStatus, getAllSupport } from "../Redux/Actions/adminaction";

export default function Support() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [flag, setflag] = useState();
  const [Alldata, setAlldata] = useState([]);
  const [page, setpage] = useState("1");
  const [search, setsearch] = useState("");
  const [limit, setlimit] = useState("10");
  const [status, setstaus] = useState("");

  useEffect(() => {
    const fetchdata = async () => {
      try {
        const data = await dispatch(
          getAllSupport({
            page: page,
            limit: limit,
            search: search,
            status: status,
          })
        );

        setAlldata(data?.payload?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchdata();
  }, [page, limit, search, status, flag]);

  console.log(Alldata);
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Support</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={3} md={3}>
                <Form.Group>
                  <Form.Control
                    onChange={(e) => setsearch(e.target.value)}
                    type="search"
                    placeholder="Search.."
                  />
                </Form.Group>
              </Col>
              {/* <Col xxl={2} xl={3} lg={3} md={3}>
                <Form.Select aria-label="Default select example">
                  <option>Created Date Range</option>
                  <option value="1">Inactive filter</option>
                </Form.Select>
              </Col> */}
              <Col xxl={2} xl={3} lg={3} md={3}>
                <Form.Select
                  onChange={(e) => setstaus(e.target.value)}
                  aria-label="Default select example"
                >
                  <option>Status</option>
                  <option value="">All</option>
                  <option value="0">Open</option>
                  <option value="1">Close</option>
                </Form.Select>
              </Col>
              <Col
                xxl={5}
                xl={3}
                lg={3}
                md={3}
                className="d-flex justify-content-end align-items-center"
              >
                {/* <div className="cmn-btn">
                  <Link to="#">Download</Link>
                </div> */}
              </Col>
            </Row>
          </div>
        </Container>
        {/* <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/Support" className="active-tab">
                  ALL
                </Link>
              </li>
              <li>
                <Link to="#">Open 18</Link>
              </li>
              <li>
                <Link to="#">Closed 160</Link>
              </li>
            </ul>
          </div>
        </Container> */}
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {1 + (page - 1) * limit} -{" "}
                {Alldata?.supports?.length + (page - 1) * limit} of{" "}
                {Alldata?.totalCount} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={(e) => setlimit(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th className="text-start">S.No</th>
                <th>Date</th>
               <th>Name</th>
                <th>Email</th>
                <th>Message</th>

                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Alldata?.supports?.map((x, index) => {
                const serialNumber = (page - 1) * limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>{new Date(x?.createdAt).toLocaleDateString()}</td>
                    <td>
                      <Link to={`/SupportChat/${x?._id}`}>
                        <b>{x?.user_id?.full_name}</b>
                      </Link>
                    </td>
                    

                    <td>{x?.user_id?.email}</td>
                    <td>
                      <p className="width-set">{x?.message || "N/A"}</p>
                    </td>
                    <td>{x?.status == 1 ? "Closed" : "Open"}</td>
                    <td className="d-flex cmn-btn">
                      {/* <Link
                        onClick={() => {
                          dispatch(
                            changeSupportStatus({ id: x?._id, status: 0 })
                          ).then((res) => {
                            if (res?.payload?.success) {
                              setflag(!flag);
                            }
                          });
                        }}
                        className="account-btn common-colr-btn"
                      >
                        OPEN
                      </Link> */}
                      <Link
                        onClick={() => {
                          dispatch(
                            changeSupportStatus({ id: x?._id, status: 1 })
                          ).then((res) => {
                            if (res?.payload?.success) {
                              setflag(!flag);
                            }
                          });
                        }}
                        className="account-btn common-colr-btn"
                      >
                        Close
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {Alldata?.supports?.length == 0 && (
            <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
            </div>
          )}
        </div>
      </Container>

      <Pagination
        totalstuff={Alldata?.totalPages}
        limit={Alldata?.limit}
        setpages={setpage}
        search={search}
      />
    </Layout>
  );
}
