import { createSlice } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { getSupportChat } from "../Actions/adminaction";

export const supportSlice = createSlice({
  name: "supportSlice",
  initialState: {
    chat: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSupportChat?.fulfilled, (state, action) => {
        console.log(action,"hjgsjhdsag")
      if (action?.payload?.success) {
        state.chat = action.payload;
      } else {
        toast.error(action?.payload?.success);
      }
    });
  },
});

export default supportSlice.reducer;
