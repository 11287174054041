import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Editor } from "@tinymce/tinymce-react";
import { useDispatch } from "react-redux";
import { deletejob, jobdetail } from "../Redux/Actions/adminaction";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";

export default function JobDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState("");
  const [showdel, setshowdel] = useState("");
  const [flag, setflag] = useState(false);
  // const [id,setId] = useState("")
  const dispatch = useDispatch();

  useEffect(async () => {
    try {
      let data = await dispatch(jobdetail(id));

      if (data?.payload?.success) {
        setData(data?.payload?.data);
      }
    } catch (error) {
      toast.error(data?.payload?.message);
    }
  }, [id]);

  console.log(data);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>Job Details</h2>

          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/ManageJobs">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid className="height-set px-4">
        <Row>
          <Col lg={8} className="mx-auto">
            <div className="customer-form-new mb-4">
              <div className="customer-form-inner">
                <Row>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Designation </Form.Label>
                      <Form.Control
                        value={data?.desigination || "N/A"}
                        type="text"
                        placeholder="Type Here"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Location</Form.Label>
                      <Form.Control
                        value={data?.location?.location_name}
                        type="text"
                        placeholder="Type Email Here"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Offered Package </Form.Label>
                      <Form.Control
                        value={`$${data?.offer_package}`}
                        type="text"
                        placeholder="1546$ - 5348574$"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <div className=" mt-0">
                      <div className="profile-extra-details">
                        <ul className="m-0">
                          <li className="m-0">
                            <h2 className="p-0 mt-0 mb-2">Preferences</h2>
                            <div className="fields-options">
                              {data?.preference?.map((x) => {
                                return x == "0" ? (
                                  <p>Remote</p>
                                ) : x == "1" ? (
                                  <p>Inperson</p>
                                ) : (
                                  <p>Hybrid</p>
                                );
                              })}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className=" mt-0">
                      <div className="profile-extra-details">
                        <ul className="m-0">
                          <li className="m-0">
                            <h2 className="p-0 mt-0 mb-2">Job Type</h2>
                            <div className="fields-options">
                              {data?.job_type?.map((x) => {
                                return x == "0" ? (
                                  <p>Full time </p>
                                ) : x == "1" ? (
                                  <p>Contract </p>
                                ) : (
                                  <p>Part Time</p>
                                );
                              })}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className=" mt-0">
                      <div className="profile-extra-details">
                        <ul className="m-0">
                          <li className="m-0">
                            <h2 className="p-0 mt-0 mb-2">Status </h2>
                            <div className="fields-options">
                              {data?.status == "0" ? (
                                <p>Connecting with Candidates</p>
                              ) : (
                                <p>Actively Hiring</p>
                              )}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        value={data?.description?.type}
                        as="textarea"
                        Row={5}
                        placeholder="Type Here"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Responsibilities </Form.Label>
                      <Form.Control
                        value={data?.description?.responsibilities}
                        type="text"
                        placeholder="Type Here"
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>what candidate should bring...</Form.Label>
                      <Form.Control
                        value={data?.description?.candidate_should_bring}
                        type="text"
                        placeholder="Type Here"
                      />
                    </Form.Group>
                  </Col>
                  <div className="cmn-btn text-center mt-5">
                    <Link
                      to="#"
                      onClick={() => {
                        setshowdel(true);
                        // setId(data?._id)
                      }}
                    >
                      Delete Job Post
                    </Link>
                  </div>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={showdel} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to Delete this Job
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button
            className="theme-btn"
            onClick={async() => {
              try {
                const res = await dispatch(deletejob({ job_id: id }));
                if (res?.payload?.success) {
                  setflag(!flag);
                  setshowdel(false);
                  navigate("/ManageJobs");
                }
              } catch (error) {
                console.error("An error occurred while deleting job:", error);
              }
            }}
          >
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowdel(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
