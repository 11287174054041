import { createSlice } from "@reduxjs/toolkit";
import { getuserDetails, userMgmtData } from "../Actions/adminaction";
import { toast } from "react-toastify";

export const userMgmtSlice = createSlice({
  name: "userMgmtSlice",
  initialState: {
    userMgmtData: null,
    employeData: null,
    userpgno:null,
    employepgno:null,
    customerpath:null,
    employpath:null,
  },
  reducers: {
    UserMgmtpgno:(state,action)=>{
      state.userpgno=action.payload
    },
    employepgno:(state,action)=>{
      state.employepgno=action.payload
    },
    userpath:(state,action)=>{
       state.customerpath = action.payload
    },
    emppath:(state,action)=>{
       state.employpath = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userMgmtData.fulfilled, (state, action) => {
      if (action?.payload?.success) {
        state.userMgmtData = action.payload.data;
      } else {
        toast.error(action?.payload?.message);
      }
    });
    builder.addCase(getuserDetails.fulfilled, (state, action) => {
      if (action?.payload?.success) {
        state.employeData = action.payload.data;
      } else {
        toast.error(action?.payload?.message);
      }
    });
  },
});
export const {UserMgmtpgno,employepgno,userpath,emppath} = userMgmtSlice.actions

export default userMgmtSlice.reducer;
