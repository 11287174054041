import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Modal } from "react-bootstrap";
import { deleteNoti, notificationList } from "../Redux/Actions/adminaction";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../Components/Layout/Pagination";

export default function Notification() {
  const data = useSelector((state) => state?.notiSlice?.listing);
  console.log(data, "data");

  const dispatch = useDispatch();
  const [pages, setPages] = useState("1");
  const [limit, setlimit] = useState("10");
  const [search, setSearch] = useState("");
  const [flag, setflag] = useState(false);
  const [showdel, setshowdel] = useState(false);
  const [id, setId] = useState("");

  useEffect(() => {
    dispatch(notificationList({ page: pages, search: search, limit: limit }));
  }, [search, pages, limit, flag]);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Notifications</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search.."
                  />
                </Form.Group>
              </Col>

              <Col
                xxl={9}
                xl={9}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                <Link to="/AddNotification">Create New</Link>
                </div>
                {/* <div className="cmn-btn">
                  <Link to="/ReportDetails">Report</Link>{" "}
                </div> */}
              </Col>

              {/* <Col className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn">
                  <Link to="/AddNotification">Create New</Link>
                </div>
              </Col> */}
            </Row>
          </div>
        </Container>
        {/* <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/Notification" className="active-tab">
                  ALL
                </Link>
              </li>
              <li>
                <Link to="#">Super Admin 2</Link>
              </li>
              <li>
                <Link to="#">Accounts 1</Link>
              </li>
            </ul>
          </div>
        </Container> */}
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {1 + (pages - 1) * limit} -{" "}
                {data?.notifications?.length + (pages - 1) * limit} of{" "}
                {data?.totalCount} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  onChange={(e) => setlimit(e.target.value)}
                  name="limit"
                  value={limit}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Create Date</th>
                <th>Title</th>
                <th>Description</th>
                <th>Sent To</th>
                {/* <th>Status</th> */}
                <th>Action </th>
              </tr>
            </thead>
            <tbody>
              {data?.notifications?.map((noti, index) => {
                return (
                  <tr>
                    <td>{(pages - 1) * limit + index + 1}</td>
                    <td>{new Date(noti?.createdAt).toLocaleDateString()}</td>
                    <td>{noti?.title || "N/A"}</td>
                    <td>{noti?.desc || "N/A"}</td>
                    <td>
                      {noti?.send_to == "1"
                        ? "All Candidates"
                        : noti?.send_to == "2"
                        ? "All Employers"
                        : "All Users"}
                    </td>
                    {/* <td>Active</td> */}
                    <td className="cmn-btn">
                      <Link
                        onClick={() => {
                          setshowdel(true);
                          setId(noti?._id);
                        }}
                        className="account-btn common-colr-btn"
                      >
                        DELETE
                      </Link>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {data?.notifications?.length == 0 && (
            <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
            </div>
          )}
        </div>
      </Container>
      <Pagination
        totalstuff={data?.totalCount}
        limit={limit}
        setPages={setPages}
        search={search}
      />

      <Modal show={showdel} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to Delete this Notification
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button
            className="theme-btn"
            onClick={() => {
              dispatch(deleteNoti({ notificationId: id })).then((res) => {
                if (res?.payload?.success) {
                  setflag(!flag);
                  setshowdel(false);
                }
              });
            }}
          >
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowdel(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal show={show} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to suspend this Account
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handlesuspend}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal> */}
    </Layout>
  );
}
