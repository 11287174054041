import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import { createAdd } from "../Redux/Actions/adminaction";
// import Autocomplete from "react-google-autocomplete";

export default function CreateAdvertisment() {
  // const Citydata = useSelector((state) => state.cityData.cityList);
  // console.log(Citydata, "city");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const titleref = useRef(null);
  const [imgvid, setimgvid] = useState("");
  const [checkforimg, setcheckForImg] = useState("");
  const [checkforaud, setcheckForAud] = useState("");
  const [audio, setAudio] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const cityref = useRef(null);
  const urlref = useRef(null);
  const [validation, setValidation] = useState({
    titleerr: "",
    imgviderr: "",
    auderr: "",
    // cityerr: "",
    utlerr: "",
  });
  const [prev, setPrev] = useState("");

  const handleDeleteAds = async (id) => {
    // try {
    //   const data = await dispatch(deleteAds({ addsId: id }));
    //   console.log(data);
    //   if (data?.payload?.status == 200) {
    //     toast.success(data?.payload?.data?.message);
    //     navigate("/CreateAdvertisment");
    //   } else {
    //     toast.error(data?.payload?.data?.message);
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const handlesave = () => {
    let isvalid = true;
    console.log(titleref?.current?.value);
    if (!titleref?.current?.value) {
      setValidation((old) => ({ ...old, titleerr: "Required" }));
      isvalid = false;
    }

    if (!imgvid) {
      setValidation((old) => ({ ...old, imgviderr: "Required" }));
      isvalid = false;
    }

    console.log(imgvid, "video");
    console.log(imgvid?.type == "video/mp4", "condition");

    // if (!selectedCity) {
    // setValidation((old) => ({ ...old, cityerr: "Required" }));
    // isvalid = false;
    // }
    if (!urlref?.current?.value) {
      setValidation((old) => ({ ...old, utlerr: "Required" }));
      isvalid = false;
    }

    if (isvalid) {
      let check = true;
      if (imgvid?.type == "video/mp4") {
        if (audio) {
          setValidation((old) => ({
            ...old,
            auderr: "Can't choose audio if you selected a video file",
          }));
          check = false;
        }
        if (check) {
          let formdata = new FormData();
          formdata.append("title", titleref?.current?.value);
          // formdata.append("city", selectedCity);
          formdata.append("link", urlref?.current?.value);
          formdata.append("image_video", imgvid);
          formdata.append("audio", audio);

          dispatch(createAdd(formdata)).then((data) => {
            console.log(data)
            if (data?.payload?.success) {
              navigate("/ManageAdvertisment");
              toast.success(data?.payload?.data?.message);
            }
          });
        }
      } else {
        let formdata = new FormData();
        formdata.append("title", titleref?.current?.value);
        // formdata.append("city", selectedCity);
        formdata.append("link", urlref?.current?.value);
        formdata.append("image_video", imgvid);
        formdata.append("audio", audio);

        dispatch(createAdd(formdata)).then((data) => {
          if (data?.payload?.success) {
            navigate("/ManageAdvertisment");
            toast.success(data?.payload?.data?.message);
          }
        });
      }
    }
  };

  const errorhandling = (fieldname) => {
    setValidation((old) => ({ ...old, [`${fieldname}err`]: "" }));
  };

  // const handlePlaceSelect = (place) => {
  // setSelectedCity(place?.address_components[0]?.long_name);
  // // console.log("Selected place: ", place);
  // // console.log("City Name: ", place.name);
  // // setlat(place.geometry.location.lat());
  // // setlong(place.geometry.location.lng());
  // setValidation((old) => ({ ...old, cityerr: "" }));
  // };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Create Advertisement</h2>
          </div>
          {/* <div></div> */}
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/ManageAdvertisment">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              className="d-flex justify-content-end align-items-center"
            >
              <div className="cmn-btn"></div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <Row className="justify-content-center">
          <Col lg={8}>
            <Row>
              <Col lg={12}>
                <div className="customer-form">
                  <Row>
                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          ref={titleref}
                          onChange={() => errorhandling("title")}
                          type="text"
                          placeholder="Type Here"
                        />
                        <Form.Label className="text-danger">
                          {validation?.titleerr}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Image / video</Form.Label>
                        <div className="event-image-upload">
                          <input
                            type="file"
                            // value={imgvid}
                            onChange={(e) => {
                              let file = e.target.files[0];
                              setcheckForImg(e.target.files);

                              if (file) {
                                setPrev(URL.createObjectURL(file));
                                setimgvid(file);
                                setValidation((old) => ({
                                  ...old,
                                  imgviderr: "",
                                }));
                              }
                            }}
                            className="form-control"
                          />
                          <p>Upload Image</p>

                          {checkforimg.length > 0 &&
                          checkforimg[0]?.type?.startsWith("video/") ? (
                            checkforimg[0]?.type == "video/mp4" ? (
                              <video className="preview-img">
                                {" "}
                                <source
                                  src={prev ? prev : null}
                                  type="video/mp4"
                                />
                              </video>
                            ) : (
                              toast.error("Please upload mp4 video")
                            )
                          ) : (
                            <img
                              className="preview-img"
                              src={prev ? prev : null}
                            />
                          )}
                        </div>
                        <Form.Label className="text-danger">
                          {validation?.imgviderr}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Audio</Form.Label>
                        <div>
                          <input
                            onChange={(e) => {
                              let file = e.target.files[0];

                              setValidation((old) => ({ ...old, auderr: "" }));

                              if (file) {
                                // Check if file is MP3
                                if (file.type === "audio/mpeg") {
                                  // Set the audio file
                                  setAudio(file);
                                } else {
                                  // Show toast error for invalid file type
                                  toast.error(
                                    "Please upload an MP3 audio file"
                                  );
                                }
                              }
                            }}
                            type="file"
                            className="form-control"
                          />
                        </div>
                        <Form.Label className="text-danger">
                          {validation?.auderr}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* <Row>
<Col lg={12}>
<Form.Group className="mb-3">
<Form.Label>Audio</Form.Label>
<div>
<input type="file" className="form-control" />
</div>
</Form.Group>

<Form.Label className="text-danger">
{validation?.auderr}
</Form.Label>
</Col>
</Row> */}
                  <Row>
                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Link</Form.Label>
                        <Form.Control
                          ref={urlref}
                          onChange={() => errorhandling("utl")}
                          type="text"
                          placeholder="Type Link Here"
                        />
                        <Form.Label className="text-danger">
                          {validation?.utlerr}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div
                    onClick={handlesave}
                    className="event-dlt-btn mt-4 text-end"
                  >
                    <Link to="#">Save</Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          {/* <Col lg={4}>
<div className="product-overview-right">
<div className="product-overview-box">
<h2>Action</h2>
<div className="event-dlt-btn" onClick={handleDeleteAds}>
<Link>Delete It</Link>
</div>
</div>
</div>
</Col> */}
        </Row>
      </Container>
    </Layout>
  );
}
