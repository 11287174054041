import React from "react";
import Login from "./pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginOtp from "./pages/LoginOtp";
import ForgotPassword from "./pages/ForgotPassword";
import UserManagement from "./pages/UserManagement";

import Support from "./pages/Support";
import Report from "./pages/Report";

import Dashboard from "./pages/Dashboard";

import Supportchat from "./pages/SupportChat";
import ReportDetails from "./pages/ReportDetails";

import Employers from "./pages/Employers";
import CustomerProfile from "./pages/CustomerProfile";
import ManageJobs from "./pages/ManageJobs";
import Notification from "./pages/Notification";
import AddNotification from "./pages/AddNotification";
import ChangePassword from "./pages/ChangePassword";
import JobDetails from "./pages/JobDetails";
import ManageChat from "./pages/ManageChat";
import UserProfileEmployer from "./pages/UserProfileEmployer";
import ForgotPasswordTwo from "./pages/ForgotPasswordtwo";
import ProtectedRoutes from "./Components/ProtectedRoutes";
import ManageAdvertisment from "./pages/ManageAdvertisment";
import CreateAdvertisment from "./pages/CreateAdvertisment";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Otp" element={<LoginOtp />} />
          <Route path="/Forgot-Password" element={<ForgotPassword />} />
          <Route path="/change-password" element={<ForgotPasswordTwo />} />
          <Route element={<ProtectedRoutes />}>
            <Route
              path="/UserProfileEmployer/:id"
              element={<UserProfileEmployer />}
            />
            <Route path="/ManageChat" element={<ManageChat />} />
            <Route path="/JobDetails/:id" element={<JobDetails />} />

            <Route path="/UserManagement" element={<UserManagement />} />
            <Route path="/Support" element={<Support />} />
            <Route path="/Report" element={<Report />} />
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/CustomerProfile/:id" element={<CustomerProfile />} />
            <Route path="/Supportchat/:id" element={<Supportchat />} />
            <Route path="/ReportDetails/:id" element={<ReportDetails />} />
            <Route path="/Employers" element={<Employers />} />
            <Route path="/ManageJobs" element={<ManageJobs />} />
            <Route path="/Notification" element={<Notification />} />
            <Route path="/AddNotification" element={<AddNotification />} />
            <Route path="/ChangePassword" element={<ChangePassword />} />

            <Route
              path="/ManageAdvertisment"
              element={<ManageAdvertisment />}
            />
            <Route
              path="/CreateAdvertisment"
              element={<CreateAdvertisment />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
