import React, { useEffect, useState } from "react";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Figure, FormLabel, Modal } from "react-bootstrap";
import {
  ChangeStatus,
  deleteAccount,
  getuserDetails,
  saveprofiledata,
} from "../Redux/Actions/adminaction";
import { useDispatch, useSelector } from "react-redux";
import logo from "../Assets/Images/C.svg";
import { userpath } from "../Redux/Reducer/userMgmtSlice";

export default function CustomerProfile() {
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const data = useSelector((state) => state.usermgmtSlice.employeData);
  const pathfromredux = useSelector(
    (state) => state.usermgmtSlice.customerpath
  );
  console.log(pathfromredux, "REDUX");
  const [show, setshow] = useState(false);
  const [showd, setshowd] = useState(false);
  

  // console.log(data,"DATA")

  const [profileData, setprofileData] = useState({
    company_name: "",
    about_me: "",
    full_name: "",
  });
  const [error, seterror] = useState({
    company_nameerr: "",
    about_meerr: "",
    full_nameerr: "",
  });

  useEffect(() => {
    dispatch(getuserDetails(id));
  }, [id]);

  useEffect(() => {
    setprofileData(data);
    // setprofileData((old)=>({...old,company_name:data?.current_role?.company}))
  }, [data]);

  // useEffect(() => {
  //   dispatch(userpath(location?.pathname?.split("/")?.[1]));
  // }, [location?.pathname]);

  // useEffect(()=>{
  //   console.log(pathfromredux.split("/"), "REDUX");

  // },[])

  const handlechange = (e) => {
    setprofileData((old) => ({ ...old, [e.target.name]: e.target.value }));
    seterror((old) => ({ ...old, [`${e.target.name}err`]: "" }));
  };

  const handlesave = () => {
    let isvalid = true;
    let err = {
      company_nameerr: "",
      about_meerr: "",
      full_nameerr: "",
    };
    if (!profileData?.about_me) {
      err.about_meerr = "Required*";
      isvalid = false;
    }
    if (!profileData?.company_name) {
      err.company_nameerr = "Required*";
      isvalid = false;
    }
    if (!profileData?.full_name) {
      err.full_nameerr = "Required*";
      isvalid = false;
    }
    if (!isvalid) {
      seterror(err);
    }
    if (isvalid) {
      // console.log(profileData)
      // let editdata=new FormData()

      // editdata.append("company_name",profileData?.company_name)
      // editdata.append("about_me",profileData?.about_me)
      // editdata.append("full_name",profileData?.full_name)
      // editdata.append("userId",data?._id)

      const editedData = Object.keys(profileData).reduce((acc, key) => {
        if (profileData[key] !== data[key]) {
          acc[key] = profileData[key];
        }
        return acc;
      }, {});

      // console.log(editedData,"how");
      editedData.userId = data?._id;

      // Dispatch action to save profile data
      console.log(data);
      dispatch(saveprofiledata(editedData));
    }
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Candidate Profile</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to={`/${pathfromredux}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4">
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={8}>
                <div className="user-profile-main">
                  <Row>
                    <Col lg={12}>
                      <Figure className="d-flex customer-form-new">
                        <div>
                          {data?.profile_image ? (
                            <Figure.Image
                              width={171}
                              height={180}
                              alt="171x180"
                              src={`${BASE_URL}${data?.profile_image}`}
                            />
                          ) : (
                            <Figure.Image
                              width={171}
                              height={180}
                              alt="171x180"
                              src={logo}
                            />
                          )}
                          <div className="cmn-top-fields p-0 text-center">
                            <FormLabel>
                              <b>Account status</b>
                            </FormLabel>
                            <div className="cmn-btn">
                              <Link to="#">
                                {" "}
                                {data?.status == 1 ? "Active" : "In-active"}
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="customer-form-inner">
                          <Row>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Profile Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Type Here"
                                  value={profileData?.profile_name}
                                  name="company_name"
                                  onChange={handlechange}
                                />
                                <Form.Label className="text-danger">
                                  {error?.company_nameerr}
                                </Form.Label>
                              </Form.Group>
                            </Col>
                            <Col lg={6}>
                              <Form.Group className="mb-3">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder="Type Here"
                                  value={profileData?.full_name}
                                  name="full_name"
                                  onChange={handlechange}
                                />
                                <Form.Label className="text-danger">
                                  {error?.full_nameerr}
                                </Form.Label>
                              </Form.Group>
                            </Col>

                            <Col lg={12}>
                              <Form.Group className="mb-3">
                                <Form.Label>About Me</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  Row={5}
                                  type="text"
                                  placeholder="Type Here"
                                  value={profileData?.about_me}
                                  name="about_me"
                                  onChange={handlechange}
                                />
                                <Form.Label className="text-danger">
                                  {error?.about_meerr}
                                </Form.Label>
                              </Form.Group>
                            </Col>
                            <div className="cmn-btn d-flex justify-content-end">
                              <Link onClick={handlesave}>Save</Link>
                            </div>
                          </Row>
                        </div>
                      </Figure>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col lg={12}>
                    <div className="user-profile-main mt-4 mb-4 px-3 py-4 ">
                      <div className="property-main mt-0">
                        <div className="profile-extra-details">
                          <ul>
                            <li>
                              <h2>Fields of Expertise</h2>
                              <div className="fields-options">
                                {data?.industries?.map((x) => {
                                  return <p>{x?.name}</p>;
                                })}
                                {data?.other_industry ?<p>Other</p> : ""}

                                {data?.industries?.length == 0 && (
                                  <p>No data updated yet</p>
                                )}
                              </div>
                            </li>
                            <li>
                              <h2>Education</h2>
                              <div className="fields-options">
                                {data?.education?.map((x) => {
                                  return <p>{x?.university}</p>;
                                })}
                                {data?.education?.length == 0 && (
                                  <p>No data updated yet</p>
                                )}
                              </div>
                            </li>
                            <li>
                              <h2>Role Open to</h2>
                              <div className="fields-options">
                                {data?.roles?.map((x) => (
                                  <p>{x}</p>
                                ))}
                                {data?.roles?.length == 0 && (
                                  <p>No data available yet</p>
                                )}
                              </div>
                            </li>
                            <li>
                              <h2>Preferences</h2>
                              <div className="fields-options">
                                {data?.preferences?.map((x) => {
                                  return (
                                    <p>
                                      {x == 0
                                        ? "Remote"
                                        : x == 1
                                        ? "Inperson"
                                        : "Hybrid"}
                                    </p>
                                  );
                                })}
                                {data?.preferences?.length == 0 && (
                                  <p>No data updated yet</p>
                                )}
                                {/* <p>Remote</p>
                                <p>Inperson</p>
                                <p>Hybrid</p> */}
                              </div>
                            </li>
                            <li>
                              <h2>Skills</h2>
                              <div className="fields-options">
                                {data?.skills?.map((x) => {
                                  return <p>{x}</p>;
                                })}
                                {data?.skills?.length == 0 && (
                                  <p>No data available yet</p>
                                )}
                              </div>
                            </li>
                            <li>
                              <h2>Current Role</h2>
                              <div className="fields-options">
                                <p>
                                  {data?.current_role?.headline ||
                                    "No data available yet"}
                                </p>
                                {/* <p>Startups</p>
                                <p>Tech</p>
                                <p>Hybrid</p> */}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <div className="user-profile-main mt-4 mb-4 px-3 py-4 ">
                      <div className="property-main mt-0">
                        <h2 className="property mb-3">Gallery</h2>
                        {/* <div className="off-treatements">
                          <ul>
                            <li>Aesthetic Clinic </li>
                            <li>All Treatments </li>
                            <li>Body </li>
                            <li>Brow Treatments </li>
                            <li>Barbers </li>
                            <li>Facial Treatments </li>

                            <li>Holistic Therapies </li>
                            <li>Hair Treatments </li>
                          </ul>
                        </div> */}
                        <Row>
                          {data?.recent_photos?.map((x) => {
                            return (
                              <Col lg={3} className="mb-3">
                                <img
                                  className="profile-add-img"
                                  src={`${BASE_URL}${x}`}
                                />
                              </Col>
                            );
                          })}
                          {data?.recent_photos?.length == 0 && (
                            <p>No posts available</p>
                          )}
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>

              </Col>
              <Col lg={4}>
                <Row>
                  <Col lg={12}>
                    <div className="user-profile-main mb-4 px-3 py-4 ">
                      <div className="property-main mt-0">
                        <h2 className="property mb-3">Intro Video</h2>
                        <Row>
                          <Col lg={12} className="mb-3">
                            {/* <img
                              className="profile-add-img"
                              src={`${BASE_URL}${data?.introduction_video}`}
                            /> */}
                            {(data?.introduction_video && (
                              <video width="100%" height="240" controls>
                                <source
                                  src={`${BASE_URL}${data?.introduction_video}`}
                                  type="video/mp4"
                                />
                              </video>
                            )) || <p>No data updated yet</p>}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <div className="d-flex justify-content-between mb-4">
                          <h2 className="property">DOCUMENTS</h2>
                          {/* <div className="cmn-btn">
                            <Link to="#">ADD</Link>
                          </div> */}
                        </div>
                        <div className="default-links">
                          <ul>
                            <li>
                              <h6>
                                {data?.resume_file
                                  ? `${BASE_URL}${data?.resume_file}`
                                  : "No data updated yet"}
                              </h6>
                              <p>{data?.resume_file ? "PDF" : null}</p>
                            </li>
                            
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

               

                <Row>
                  <Col lg={12}>
                    <div className="user-profile-main px-3 mt-4 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Activity</h2>
                        <div className="active-default-links">
                          <ul>
                            <li>
                              <h6>[Admin Name] lorem ipsum is dummy text.</h6>
                              <p>01/01/2023 13:32</p>
                            </li>
                            <li>
                              <h6>[Admin Name] lorem ipsum is dummy text.</h6>
                              <p>01/01/2023 13:32</p>
                            </li>
                            <li>
                              <h6>[Admin Name] lorem ipsum is dummy text.</h6>
                              <p>01/01/2023 13:32</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg={6} className="mb-5">
                <div className="user-profile-main px-3 py-4 h-100">
                  <Row>
                    <Col lg={12}>
                      <div className="property-main mt-0">
                        <h2 className="property">Delete Account</h2>
                        <Figure.Caption className="m-0 mt-2">
                          <p>
                            Once you delete your account, you can not retrieve
                            the account. Please be certain.
                          </p>
                        </Figure.Caption>
                        <div class="dlt-ac-btn mt-4">
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                            setshowd(true)
                              // dispatch(deleteAccount({ userId: id })).then(
                              //   (res) => {
                              //     if (res?.payload?.success) {
                              //       navigate("/UserManagement");
                              //     }
                              //   }
                              // )
                            }
                          >
                            Delete Account
                          </a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6} className="mb-5">
                <div className="user-profile-main px-3 py-4 h-100">
                  <Row>
                    <Col lg={12}>
                      <div className="property-main mt-0">
                        <h2 className="property">Suspend account</h2>
                        <Figure.Caption className="m-0 mt-2">
                          <p>
                            You will not be able to receive messages,
                            notifications for up to 24hours.
                          </p>
                        </Figure.Caption>
                        <div
                          class="dlt-ac-btn mt-4"
                          style={{ cursor: "pointer" }}
                        >
                          <a
                            // href="/Support"
                            onClick={() => setshow(true)}
                          >
                            Suspend Account
                          </a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>



      <Modal show={show} className="delete-popup">
        <Modal.Body>
          <p className="are-sure-text">
            Are you sure you want to suspend this account?
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button
             className="theme-btn"
            onClick={() => {
              dispatch(
                ChangeStatus({
                  userId: id,
                  type: 0,
                })
              ).then((res) => {
                if (res?.payload?.success) {
                  setshow(false);
                }
              });
            }}
          >
            Yes
          </Button>
          <Button  className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showd} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are You Sure You Want To delete This Account
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
        <Button
            className="theme-btn"
            onClick={() => {
              dispatch(deleteAccount({ userId: id })).then((res) => {
                if (res?.payload?.success) {
                  navigate("/UserManagement");
                }
              });
            }}
          >
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
          
        </Modal.Footer>
      </Modal>



    </Layout>
  );
}
