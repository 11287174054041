import { createSlice } from "@reduxjs/toolkit";
import { addsList, getreportDetails, notificationList, reportList } from "../Actions/adminaction";
import { toast } from "react-toastify";

export const adsSlice = createSlice({
  name: "adsSlice",
  initialState: {
    listing:"",
   
   
  },
  reducers: {
   
  },
  extraReducers: (builder) => {
    builder.addCase(addsList.fulfilled, (state, action) => {
        console.log(action.payload)
      if (action?.payload) {
        state.listing = action.payload;
      } else {
        toast.error(action?.payload?.message);
      }
    });
  
   
  },
});


export default adsSlice.reducer;